import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { ClassicSpinner } from "react-spinners-kit";
import {
  server,
  config,
  signage_url,
  partner_url,
  enterprise_url,
} from "../env";
import axios from "axios";
import Alert from "../components/alert";

export default class Dashbaord extends PureComponent {
  state = {
    active_tab: "services",
    loader: "",
    spinner: (
      <ClassicSpinner
        size={13}
        className="d-inline-block"
        color="#fff"
        loading={true}
      />
    ),
    message: "",
    user_type: "user",
    plateform: localStorage.getItem("plateform"),
  };

  logout = () => {
    this.setState({ active_tab: "logout" });
    localStorage.removeItem("token");
    localStorage.removeItem("tokenDate");
    this.props.history.push("/login");
    window.location.reload();
  };

  componentDidMount = () => {
    axios
      .post(server + "/account/verify", {}, config)
      .then((rsp) => {
        const token = localStorage.getItem("token");
        if (rsp.data.hasOwnProperty("is_user")) {
          window.location.href = signage_url + token;
        } else if (rsp.data.hasOwnProperty("is_enterprise")) {
          window.location.href = enterprise_url + token;
        } else if (rsp.data.hasOwnProperty("is_partner")) {
          window.location.href = partner_url + token;
        }
      })
      .catch((error) => {
        window.location.href = "/logout";
      });
  };

  updatePassword = (event) => {
    event.preventDefault();
    if (event.target.new_password.value !== event.target.cnf_password.value) {
      this.setState({
        message: (
          <Alert className="alert-danger">Confirm password doesn't match</Alert>
        ),
      });
      return;
    }
    this.setState({
      loader: this.state.spinner,
    });

    const params = {
      old_password: event.target.old_password.value,
      new_password: event.target.new_password.value,
    };

    axios
      .post(server + "/account/update_password", params, config)
      .then((rsp) => {
        localStorage.setItem("token", rsp.data.token);
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-success">
              <span>{rsp.data.detail}</span>
            </Alert>
          ),
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-danger">
              <span>{error.response.data.detail}</span>
            </Alert>
          ),
        });
      });
  };

  render() {
    const { active_tab, message, loader, user_type } = this.state;
    return (
      <div className="d-none">
        <div className="wilyer-signin" style={{ maxWidth: "55rem" }}>
          <div className="card rounded-xl mt-4 shadow-sm">
            <div className="justify-content-end text-right pt-3 pr-5">
              <span className="badge bg-secondary rounded-pill px-2">
                {localStorage.getItem("email")}
              </span>
            </div>
            <div className="text-center p-5 pb-0">
              <img
                src="https://signage.wilyer.com/assets/img/signage.png"
                height="50"
                width="100"
                className="navbar-brand-img mx-auto"
                alt="Wilyer Signage"
              />
              <div className="h4 pt-3">Wilyer Accounts & Services</div>
            </div>
            <div className="card-body p-5">
              <div className="mb-5">
                <ul className="nav nav-pills justify-content-center">
                  <li className="nav-item">
                    <Link
                      className={
                        active_tab === "services"
                          ? "nav-link active"
                          : "nav-link bg-light"
                      }
                      aria-current="page"
                      to="#"
                      onClick={() => this.setState({ active_tab: "services" })}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item border-0">
                    <Link
                      className={
                        active_tab === "settings"
                          ? "nav-link active"
                          : "nav-link bg-light"
                      }
                      to="#"
                      onClick={() => this.setState({ active_tab: "settings" })}
                    >
                      Account Settings
                    </Link>
                  </li>
                  <li className="nav-item border-0">
                    <Link
                      className={
                        active_tab === "logout"
                          ? "nav-link active"
                          : "nav-link bg-light text-danger"
                      }
                      to="#"
                      onClick={this.logout}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
              {active_tab === "services" ? (
                <div className="row gy-4">
                  <div className="col-sm-4">
                    <div className="card shadow-sm">
                      <img
                        src="https://media-exp1.licdn.com/dms/image/C511BAQFuFJIgabp-2w/company-background_10000/0?e=2159024400&v=beta&t=enE8Uags7QxMyLKaKI_RgSjhRMBqlmco-dofMp8wVj4"
                        alt=""
                        className="card-img-top"
                      />
                      <div className="card-body">
                        <strong>Wilyer Signage</strong> <br />
                        <button className="btn btn-sm btn-primary mt-3">
                          Dashbaord
                        </button>
                      </div>
                    </div>
                  </div>

                  {user_type === "partner" ? (
                    <div className="col-sm-4">
                      <div className="card shadow-sm">
                        <img
                          src="https://media-exp1.licdn.com/dms/image/C511BAQFuFJIgabp-2w/company-background_10000/0?e=2159024400&v=beta&t=enE8Uags7QxMyLKaKI_RgSjhRMBqlmco-dofMp8wVj4"
                          alt=""
                          className="card-img-top"
                        />
                        <div className="card-body">
                          <strong>Wilyer Partner</strong> <br />
                          <button className="btn btn-sm btn-primary mt-3">
                            Dashbaord
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : active_tab !== "logout" ? (
                <div className="p-3 pt-0">
                  <div className="card-header border-0 bg-transparent">
                    <h6 className="card-title">Change Password</h6>
                  </div>
                  <form onSubmit={this.updatePassword}>
                    <div className="card-body">
                      <div className="form-group">{message}</div>
                      <div className="form-group">
                        <label htmlFor="old_password">Old Password</label>
                        <input
                          type="password"
                          name="old_password"
                          id="old_password"
                          className="form-control rounded-pill"
                          autoFocus={true}
                          required
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="new_password">New Password</label>
                        <input
                          type="password"
                          name="new_password"
                          id="new_password"
                          className="form-control rounded-pill"
                          required
                        />
                      </div>
                      <div className="form-group mt-4">
                        <label htmlFor="cnf_password">Confirm Password</label>
                        <input
                          type="password"
                          name="cnf_password"
                          id="cnf_password"
                          className="form-control rounded-pill"
                          required
                        />
                      </div>
                      <div className="form-group text-right mt-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-sm rounded-pill px-3"
                        >
                          Update Password {loader}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
