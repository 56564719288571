import React from "react";

function Alert(props) {
  return (
    <div
      className={"alert rounded-lg alert-dismissible " + props.className}
      role="alert"
    >
      {props.children}
    </div>
  );
}

export default Alert;
