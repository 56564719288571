import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { server, SITE_KEY, logo } from "../env";
import axios from "axios";
import Alert from "../components/alert";
import { ClassicSpinner } from "react-spinners-kit";

export default class Forgot extends PureComponent {
  state = {
    reCaptcha: null,
    message: "",
    laoder: "",
    spinner: (
      <ClassicSpinner
        size={13}
        className="d-inline-block"
        color="#fff"
        loading={true}
      />
    ),
  };

  forgot = (event) => {
    event.preventDefault();
    this.setState({
      loader: this.state.spinner,
    });

    const params = {
      email: event.target.email.value,
      "g-recaptcha-response": this.state.reCaptcha,
    };

    var email = event.target.email.value;

    axios
      .post(server + "/account/forgot", params)
      .then((rsp) => {
        this.setState({
          loader: "",
          message: <Alert className="alert-success">{rsp.data.detail}</Alert>,
        });
        localStorage.setItem("email", email);
        this.props.history.push("/reset");
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-danger">
              <span>{error.response.data.detail}</span>
            </Alert>
          ),
        });
      });
  };

  resend_otp = () => {};

  render() {
    const { loader, message } = this.state;

    return (
      <div className="wilyer-container">
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4">
            <div className="text-center p-5 pb-0">
              <img
                src={"/assets/img/logo/" + logo}
                height="50"
                width="100"
                className="navbar-brand-img mx-auto"
                alt=""
              />
              <div className="h4 pt-3">Forgot your account password</div>
            </div>
            <div className="card-body p-5">
              <form onSubmit={this.forgot}>
                {message}
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control rounded-lg"
                    autoFocus={true}
                    required
                  />
                </div>
                <div className="form-group pt-4">
                  <button
                    type="submit"
                    className="btn btn-primary form-control rounded-lg"
                  >
                    Continue {loader}
                  </button>
                  <br />
                  <p className="pt-3 pb-0 text-center">
                    <small>
                      Remember password?
                      <Link to="/login" className="ml-1 text-decoration-none">
                        Log In
                      </Link>
                    </small>
                    <span className="pl-2 pr-2">|</span>
                    <small>
                      Don't have an account?
                      <Link to="/signup" className="ml-1 text-decoration-none">
                        Sign Up
                      </Link>
                    </small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
          <GoogleReCaptcha
            onVerify={(token) => this.setState({ reCaptcha: token })}
          />
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}
