import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { server, SITE_KEY, logo } from "../env";
import axios from "axios";
import Alert from "../components/alert";
import { ClassicSpinner } from "react-spinners-kit";

import { Eye, EyeOff } from "react-feather";

export default class Forgot extends PureComponent {
  state = {
    show_password_icon: <Eye size={14} />,
    password_type: "password",

    reCaptcha: null,
    loader: "",
    spinner: (
      <ClassicSpinner
        size={13}
        className="d-inline-block"
        color="#fff"
        loading={true}
      />
    ),
    message: (
      <Alert className="alert-warning">OTP has been sent on your email.</Alert>
    ),
  };

  show_Password = () => {
    const { password_type } = this.state;
    this.setState({
      show_password_icon:
        password_type === "password" ? <EyeOff size={14} /> : <Eye size={14} />,
      password_type: password_type === "password" ? "text" : "password",
    });
  };

  reset = (event) => {
    event.preventDefault();
    this.setState({
      loader: this.state.spinner,
    });

    const params = {
      email: localStorage.getItem("email"),
      otp: event.target.otp.value,
      new_password: event.target.new_password.value,
      "g-recaptcha-response": this.state.reCaptcha,
    };

    axios
      .post(server + "/account/reset", params)
      .then((rsp) => {
        this.setState({
          loader: "",
          message: <Alert className="alert-success">{rsp.data.detail}</Alert>,
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-danger">
              <span>{error.response.data.detail}</span>
            </Alert>
          ),
        });
      });
  };

  resend_otp = () => {
    const params = {
      email: localStorage.getItem("email"),
      "g-recaptcha-response": this.state.reCaptcha,
    };
    axios
      .post(server + "/account/forgot", params)
      .then((rsp) => {
        this.setState({
          loader: "",
          message: <Alert className="alert-success">{rsp.data.detail}</Alert>,
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-danger">
              <span>{error.response.data.detail}</span>
            </Alert>
          ),
        });
      });
  };

  render() {
    const { show_password_icon, password_type, message, loader } = this.state;
    return (
      <div className="wilyer-container">
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4">
            <div className="text-center p-5 pb-0">
              <img
                src={"/assets/img/logo/" + logo}
                height="50"
                width="100"
                className="navbar-brand-img mx-auto"
                alt="Signage"
              />
              <div className="h4 pt-3">Reset your account password</div>
            </div>
            <div className="card-body p-5">
              <form onSubmit={this.reset}>
                {message}
                <div className="form-group">
                  <label htmlFor="otp">OTP</label>
                  <input
                    type="number"
                    name="otp"
                    id="otp"
                    className="form-control rounded-lg"
                    autoFocus={true}
                    required
                  />
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="new_password">New Password</label>
                  <div className="input-group">
                    <input
                      type={password_type}
                      name="new_password"
                      id="new_password"
                      className="form-control password-field"
                      required
                    />
                    <span
                      type="button"
                      className="input-group-text bg-default"
                      onClick={this.show_Password}
                    >
                      {show_password_icon}
                    </span>
                  </div>
                </div>
                <div className="form-group pt-4">
                  <button className="btn btn-primary form-control rounded-lg">
                    Change Password {loader}
                  </button>
                  <br />
                  <p className="pt-3 pb-0 text-center">
                    <small>
                      Remember password?
                      <Link to="/login" className="ml-1 text-decoration-none">
                        Log In
                      </Link>
                    </small>
                    <span className="pl-2 pr-2">|</span>
                    <small>
                      Did't get OTP?
                      <a
                        href="javascipt:void(0)"
                        className="ml-1 text-decoration-none"
                        onClick={this.resend_otp}
                      >
                        Resend
                      </a>
                    </small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
          <GoogleReCaptcha
            onVerify={(token) => this.setState({ reCaptcha: token })}
          />
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}
