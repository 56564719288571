import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { ClassicSpinner } from "react-spinners-kit";
import { server, SITE_KEY, logo } from "../env";
import { Eye, EyeOff } from "react-feather";
import axios from "axios";
import Alert from "../components/alert";

export default class Signup extends PureComponent {
  state = {
    show_password_icon: <Eye size={14} />,
    password_type: "password",

    loader: "",
    spinner: (
      <ClassicSpinner
        size={13}
        className="d-inline-block"
        color="#fff"
        loading={true}
      />
    ),
    message: "",
    reCaptcha: null,
  };

  show_Password = () => {
    const { password_type } = this.state;
    this.setState({
      show_password_icon:
        password_type === "password" ? <EyeOff size={14} /> : <Eye size={14} />,
      password_type: password_type === "password" ? "text" : "password",
    });
  };

  signup = (event) => {
    event.preventDefault();
    this.setState({
      loader: this.state.spinner,
    });

    const params = {
      email: event.target.email.value,
      password: event.target.password.value,
      full_name: event.target.full_name.value,
      "g-recaptcha-response": this.state.reCaptcha,
    };

    localStorage.setItem("email", event.target.email.value);

    axios
      .post(server + "/account/register", params)
      .then((rsp) => {
        this.setState({
          loader: "",
          message: <Alert className="alert-success">{rsp.data.detail}</Alert>,
        });
        this.props.history.push("/confirm");
        window.location.reload();
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-warning">
              <span>{error.response.data.email}</span>
            </Alert>
          ),
        });
      });
  };

  render() {
    const { show_password_icon, password_type, message, loader } = this.state;
    return (
      <div className="wilyer-container">
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4">
            <div className="text-center p-5 pb-0">
              <img
                src={"/assets/img/logo/" + logo}
                height="50"
                width="100"
                className="navbar-brand-img mx-auto"
                alt=""
              />
              <div className="h4 pt-3">Create new account</div>
            </div>
            <div className="card-body  p-5">
              <form onSubmit={this.signup}>
                {message}
                <div className="form-group">
                  <label htmlFor="full_name">Full Name</label>
                  <input
                    type="text"
                    name="full_name"
                    id="full_name"
                    className="form-control rounded-lg"
                    autoFocus={true}
                    required
                  />
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control rounded-lg"
                    required
                  />
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="username">Password</label>
                  <div className="input-group">
                    <input
                      type={password_type}
                      name="password"
                      id="password"
                      className="form-control password-field"
                      required
                    />
                    <span
                      type="button"
                      className="input-group-text bg-default"
                      onClick={this.show_Password}
                    >
                      {show_password_icon}
                    </span>
                  </div>
                </div>
                <div className="form-group pt-4">
                  <button className="btn btn-primary form-control rounded-lg">
                    Sign Up {loader}
                  </button>
                  <br />
                  <p className="pt-3 pb-0 text-center">
                    <small>
                      Have an account?
                      <Link to="/login" className="ml-1 text-decoration-none">
                        Log In
                      </Link>
                    </small>
                    <span className="pl-2 pr-2">|</span>
                    <small>
                      Forgot password?
                      <Link to="/forgot" className="ml-1 text-decoration-none">
                        Forgot
                      </Link>
                    </small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
          <GoogleReCaptcha
            onVerify={(token) => this.setState({ reCaptcha: token })}
          />
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}
