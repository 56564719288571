import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import {
  server,
  SITE_KEY,
  signage_url,
  partner_url,
  enterprise_url,
  logo,
} from "../env";
import axios from "axios";
import Alert from "../components/alert";
import { ClassicSpinner } from "react-spinners-kit";

export default class Signup extends PureComponent {
  constructor(props) {
    super(props);
    if (localStorage.getItem("email") == null) {
      props.history.push("/login");
    }
  }
  state = {
    reCaptcha: null,
    message: (
      <Alert className="alert-warning">
        OTP has been sent to you, please verify your email.
      </Alert>
    ),
    laoder: "",
    spinner: (
      <ClassicSpinner
        size={13}
        className="d-inline-block"
        color="#fff"
        loading={true}
      />
    ),
  };

  removeAccess = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenDate");
    localStorage.removeItem("email");

    this.setState({
      loader: "",
      message: (
        <Alert className="alert-warning text-justify">
          Login using this account not allowed to{" "}
          {localStorage.getItem("plateform")} plateform. Please contact to
          support team to get access of {localStorage.getItem("plateform")}{" "}
          plateform.
        </Alert>
      ),
    });
  };

  confirm = (event) => {
    event.preventDefault();
    this.setState({
      loader: this.state.spinner,
    });

    const params = {
      email: localStorage.getItem("email"),
      otp: event.target.otp.value,
      "g-recaptcha-response": this.state.reCaptcha,
    };

    axios
      .post(server + "/account/confirm", params)
      .then((rsp) => {
        var conf = {
          headers: {
            Authorization: `Bearer ${rsp.data.token}`,
          },
        };
        localStorage.setItem("token", rsp.data.token);
        localStorage.setItem("tokenDate", new Date());
        localStorage.setItem("email", params.email);
        const token = rsp.data.token;
        const detail = rsp.data.detail;

        axios.post(server + "/account/verify", {}, conf).then((rsp) => {
          this.setState({
            loader: "",
            message: <Alert className="alert-success">{detail}</Alert>,
          });
          if (rsp.data.hasOwnProperty("is_user")) {
            window.location.href = signage_url + token;
          } else if (rsp.data.hasOwnProperty("is_enterprise")) {
            window.location.href = enterprise_url + token;
          } else if (rsp.data.hasOwnProperty("is_partner")) {
            window.location.href = partner_url + token;
          }
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-danger">
              <span>{error.response.data.detail}</span>
            </Alert>
          ),
        });
      });
  };

  resend_otp = () => {
    const params = {
      email: localStorage.getItem("email"),
      "g-recaptcha-response": this.state.reCaptcha,
    };
    axios
      .post(server + "/account/forgot", params)
      .then((rsp) => {
        this.setState({
          loader: "",
          message: <Alert className="alert-success">{rsp.data.detail}</Alert>,
        });
      })
      .catch((error) => {
        this.setState({
          loader: "",
          message: (
            <Alert className="alert-danger">
              <span>{error.response.data.detail}</span>
            </Alert>
          ),
        });
      });
  };

  render() {
    const { loader, message } = this.state;

    return (
      <div className="wilyer-container">
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4">
            <div className="text-center p-5 pb-0">
              <img
                src={"/assets/img/logo/" + logo}
                height="50"
                width="100"
                className="navbar-brand-img mx-auto"
                alt="Signage Cloud"
              />
              <div className="h4 pt-3">Confirm email address</div>
            </div>
            <div className="card-body  p-5">
              <form onSubmit={this.confirm}>
                {message}
                <div className="form-group">
                  <label htmlFor="otp">OTP</label>
                  <input
                    type="number"
                    name="otp"
                    id="otp"
                    className="form-control rounded-lg"
                    autoFocus={true}
                    required
                  />
                </div>
                <div className="form-group pt-4">
                  <button className="btn btn-primary form-control rounded-lg">
                    Confirm {loader}
                  </button>
                  <br />
                  <p className="pt-3 pb-0 text-center">
                    <small>
                      Go back to
                      <Link to="/login" className="ml-1 text-decoration-none">
                        Log In
                      </Link>
                    </small>
                    <span className="pl-2 pr-2">|</span>
                    <small>
                      Did't get OTP?
                      <a
                        href="javascipt:void(0)"
                        className="ml-1 text-decoration-none"
                        onClick={this.resend_otp}
                      >
                        Resend
                      </a>
                    </small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
          <GoogleReCaptcha
            onVerify={(token) => this.setState({ reCaptcha: token })}
          />
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}
