import React from 'react';
function Logout(props) {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenDate");
  window.location.href = "/login";
  return(
    <></>
  )
}

export default Logout;
