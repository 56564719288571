import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Login from "./auth/login";
import Signup from "./auth/signup";
import Forgot from "./auth/forgot";
import Confirm from "./auth/confirm-email";
import Reset from "./auth/reset";
import Logout from "./auth/logout";

import Dashbaord from "./dashboard/dashboard";

import validate_plateform from "./auth/validate-plateform";

function App() {
  const href = window.location.href;
  if (href.includes('thefameusmedia')){
    // update document title
    document.title = "The FameusMedia";
    // update favicon icon
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/assets/img/logo/thefameusmedia-icon.png';
  }
  if (
    Date.parse(new Date()) - Date.parse(localStorage.tokenDate) >
    23 * 60 * 60 * 1000
  ) {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenDate");
  }
  let login = localStorage.getItem("token") === null ? false : true;
  return (
    <Fragment>
      <BrowserRouter>
        <Fragment>
          <Switch>
            <Route path="/auth/:plateform" component={validate_plateform} />
            {login ? (
              <Switch>
                <Route path="/logout" component={Logout} />
                <Route path="/" component={Dashbaord} />
              </Switch>
            ) : (
              <Switch>
                <Route path="/reset" component={Reset} />
                <Route path="/forgot" component={Forgot} />
                <Route path="/confirm" component={Confirm} />
                <Route path="/signup" component={Signup} />
                <Route path="/" component={Login} />
              </Switch>
            )}
          </Switch>
        </Fragment>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
