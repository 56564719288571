const cloud = window.location.href;
const server = cloud.includes("localhost")
  ? "http://localhost:8000"
  : cloud.includes("digitos")
  ? "https://api.digitos.cc"
  : "https://api.wilyersignage.com";

var logo = "logo.png";
if (cloud.includes("signagecloud")) {
  logo = "signage-cloud-logo.png";
}

if (cloud.includes("digitos")) {
  logo = "digitos.png";
}
if (cloud.includes("itiltd")) {
  logo = "iti-ltd.png";
}
if (cloud.includes("thefameusmedia")) {
  logo = "thefameusmedia.jpg";
}

module.exports = {
  server,
  logo,
  SITE_KEY: "6LcfiE0aAAAAAAkyaJnLd8SuSBZ4RB2rq4A93CL6",
  config: {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
  live:
    server.includes("localhost") || server.includes("http://") ? false : true,
  signage_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3002/auth/"
      : cloud.includes("digitos")
      ? "https://digitos.cc/auth/"
      : cloud.includes("itiltd")
      ? "https://itiltd.signagecloud.in/auth/"
      : cloud.includes("signagecloud")
      ? "https://signagecloud.in/auth/"
      : cloud.includes("thefameusmedia")
      ? "https://digital.thefameusmedia.com/auth/"
      : "https://wilyersignage.com/auth/",

  partner_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3003/auth/"
      : cloud.includes("signagecloud")
      ? "https://partnerv2.signagecloud.in/auth/"
      : "https://partnerv2.wilyersignage.com/auth/",

  enterprise_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3002/auth/"
      : cloud.includes("digitos")
      ? "https://digitos.cc/auth/"
      : cloud.includes("signagecloud")
      ? "https://signagecloud.in/auth/"
      : "https://wilyersignage.com/auth/",

  admin_url:
    server.includes("localhost") || server.includes("http://")
      ? "http://localhost:3005/auth/"
      : cloud.includes("signagecloud")
      ? "https://hexer.signagecloud.in/auth/"
      : "https://hexer.wilyersignage.com/auth/",
};
