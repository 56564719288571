import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { ClassicSpinner } from "react-spinners-kit";

import {
  server,
  SITE_KEY,
  signage_url,
  partner_url,
  enterprise_url,
  admin_url,
  logo,
} from "../env";
import { Eye, EyeOff } from "react-feather";
import axios from "axios";
import Alert from "../components/alert";

export default class Login extends PureComponent {
  state = {
    show_password_icon: <Eye size={14} />,
    password_type: "password",

    loader: "",
    spinner: (
      <ClassicSpinner
        size={13}
        className="d-inline-block"
        color="#fff"
        loading={true}
      />
    ),
    message: "",
    reCaptcha: null,
  };

  show_Password = () => {
    const { password_type } = this.state;
    this.setState({
      show_password_icon:
        password_type === "password" ? <EyeOff size={14} /> : <Eye size={14} />,
      password_type: password_type === "password" ? "text" : "password",
    });
  };

  removeAccess = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenDate");
    localStorage.removeItem("email");

    this.setState({
      loader: "",
      message: (
        <Alert className="alert-warning text-justify">
          Login using this account not allowed to{" "}
          {localStorage.getItem("plateform")} platform. Please contact to support
          team to get access of {localStorage.getItem("plateform")} platform.
        </Alert>
      ),
    });
  };

  login = (event) => {
    event.preventDefault();
    this.setState({
      loader: this.state.spinner,
    });

    const params = {
      email: event.target.email.value,
      password: event.target.password.value,
      "g-recaptcha-response": this.state.reCaptcha,
    };
    var email = event.target.email.value;
    axios
      .post(server + "/account/login", params)
      .then((rsp) => {
        var conf = {
          headers: {
            Authorization: `Bearer ${rsp.data.token}`,
          },
        };
        localStorage.setItem("token", rsp.data.token);
        localStorage.setItem("tokenDate", new Date());
        localStorage.setItem("email", email);
        const token = rsp.data.token;
        const detail = rsp.data.detail;

        axios.post(server + "/account/verify", {}, conf).then((rsp) => {
          this.setState({
            loader: "",
            message: <Alert className="alert-success">{detail}</Alert>,
          });
          if (rsp.data.hasOwnProperty("is_superuser")) {
            window.location.href = admin_url + token;
          } else if (rsp.data.hasOwnProperty("is_user")) {
            window.location.href = signage_url + token;
          } else if (rsp.data.hasOwnProperty("is_enterprise")) {
            window.location.href = enterprise_url + token;
          } else if (rsp.data.hasOwnProperty("is_partner")) {
            window.location.href = partner_url + token;
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({
            loader: "",
            message: (
              <Alert className="alert-warning">
                <span>{error.response.data.detail}</span>
              </Alert>
            ),
          });
          localStorage.setItem("email", params.email);
          this.props.history.push("/confirm");
        } else if (error.response.status === 401) {
          this.setState({
            loader: "",
            message: (
              <Alert className="alert-danger">
                <span>{error.response.data.detail}</span>
              </Alert>
            ),
          });
        }
      });
  };

  render() {
    const { show_password_icon, password_type, loader, message } = this.state;
    return (
      <div className="wilyer-container">
        <div className="wilyer-signin">
          <div className="card rounded-xl mt-4">
            <div className="text-center p-5 pb-0">
              <img
                src={"/assets/img/logo/" + logo}
                height="50"
                width="100"
                className="navbar-brand-img mx-auto"
                alt=""
              />
              <div className="h4 pt-3">Welcome back, please login</div>
            </div>
            <div className="card-body p-5">
              {message}
              <form onSubmit={this.login}>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control rounded-lg"
                    autoFocus={true}
                    required
                  />
                </div>
                <div className="form-group pt-4">
                  <label htmlFor="username">Password</label>
                  <div className="input-group">
                    <input
                      type={password_type}
                      name="password"
                      id="password"
                      className="form-control password-field"
                      required
                    />
                    <span
                      type="button"
                      className="input-group-text bg-default"
                      onClick={this.show_Password}
                    >
                      {show_password_icon}
                    </span>
                  </div>
                </div>
                <div className="form-group pt-4">
                  <button
                    type="submit"
                    className="btn btn-primary form-control rounded-lg"
                  >
                    Log In {loader}
                  </button>
                  <br />
                  <p className="pt-3 pb-0 text-center">
                    <small>
                      Don't have an account?
                      <Link to="/signup" className="ml-1 text-decoration-none">
                        Signup
                      </Link>
                    </small>
                    <span className="pl-2 pr-2">|</span>
                    <small>
                      Forgot password?
                      <Link to="/forgot" className="ml-1 text-decoration-none">
                        Forgot
                      </Link>
                    </small>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
          <GoogleReCaptcha
            onVerify={(token) => this.setState({ reCaptcha: token })}
          />
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}
